import {useEffect, useState} from "react";
import axios from "axios";

function AdminPage () {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showAlertMessage, setShowAlertMessage] = useState(false)
    const [responses, setResponses] = useState(null)
    const [babies, setBabies] = useState(0)
    const [adultPrice, setAdultPrice] = useState(0)
    const [babyPrice, setBabyPrice] = useState(0)
    const [totalPrice, setTotalPrice] = useState(null)
    const [inputFilter, setInputFilter] = useState("")

    // controlla se l'utente è autenticato
    useEffect(() => {
        let isLogged = sessionStorage.getItem("auth");
        if (isLogged)
            setIsAuthenticated(true);
        getAllResponses();
    }, []);

    useEffect(() => {
        computeTotalPrice();
    }, [adultPrice, babyPrice]);

    useEffect(() => {
        filterResponses();
    }, [inputFilter]);

    const handleSubmit = (event) => {
        event.preventDefault();

        if (username === 'fabiana' && password === 'fantini') {
            sessionStorage.setItem("auth", "true")
            setIsAuthenticated(true)
        } else {
            setShowAlertMessage(true)
            setIsAuthenticated(false)
        }
    };

    const handleOnClickLogout = () => {
        sessionStorage.removeItem("auth")
        setIsAuthenticated(false)
    }

    const getAllResponses = (filter = null) => {
        axios.get(`https://nicolavitrani-a4783-default-rtdb.europe-west1.firebasedatabase.app/FNresponses.json`).then(response => {
            if (response.status === 200) {
                for (const key in response.data) {
                    response.data[key].id = key;
                }

                let babies = 0;
                let names = [];
                Object.values(response.data).forEach(el => {
                    el.name = el.name.trim()
                    el.surname = el.surname.trim()

                    names.push(el.name + " " + el.surname)

                    if (el.hostsData && el.hostsData.length > 0) {
                        el.hostsData.forEach(host => {
                            host.name = host.name.trim();
                            host.surname = host.surname.trim();

                            names.push(host.name + " " + host.surname)

                            if (host.menu === "baby") {
                                babies = babies +1
                            }
                        })
                    }
                })

                setBabies(babies);

                let duplicates = names.filter((item, index) => index !== names.indexOf(item));

                Object.values(response.data).forEach(el2 => {
                    el2.name = el2.name.trim()
                    el2.surname = el2.surname.trim()
                    let nameAndSurname = el2.name + " " + el2.surname;

                    if (duplicates.includes(nameAndSurname)) {
                        el2.duplicate = true;
                    } else {
                        el2.duplicate = false;
                    }

                    if (el2.hostsData && el2.hostsData.length > 0) {
                        el2.hostsData.forEach(host => {
                            host.name = host.name.trim();
                            host.surname = host.surname.trim();
                            let nameAndSurname2 = el2.name + " " + el2.surname;

                            if (duplicates.includes(nameAndSurname2)) {
                                host.duplicate = true;
                            } else {
                                host.duplicate = false;
                            }
                        })
                    }
                })

                if (filter) {
                    let inputFilterLowercase = inputFilter.toLowerCase();
                    let filteredResponses = Object.values(responses).filter(el => {
                        let matchesMainObject = el.name.toLowerCase().includes(inputFilterLowercase) || el.surname.toLowerCase().includes(inputFilterLowercase);
                        let matchesHostData = el.hostsData?.some(hostEl => hostEl.name.toLowerCase().includes(inputFilterLowercase) || hostEl.surname.toLowerCase().includes(inputFilterLowercase));

                        return matchesMainObject || matchesHostData;
                    });

                    setResponses(filteredResponses);
                } else {
                    setResponses(response.data);
                }
            } else {
                window.alert("Errore nella richiesta dei dati, riprova.")
            }
        })
    }

    const handleDeleteResponse = (responseId, responseName, responseSurname) => {
        const confirmed = window.confirm(`Eliminare la risposta di ${responseName} ${responseSurname}?`);

        if (confirmed) {
            axios.delete(`https://nicolavitrani-a4783-default-rtdb.europe-west1.firebasedatabase.app/FNresponses/${responseId}.json`)
                .then(response => {
                    if (response.status === 200) {
                        window.alert("Risposta cancellata correttamente.")
                        getAllResponses();
                    } else {
                        window.alert("Errore nella cancellazione, riprova")
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    const handleDeleteGuest = (responseId, guestIndex, guestName, guestSurname) => {
        const confirmed = window.confirm(`Eliminare l'ospite ${guestName} ${guestSurname}?`);

        if (confirmed) {
            let response = responses[responseId];
            let oldGuests = response.hostsData;
            let newHostsData = oldGuests.filter(el => el.name !== guestName && el.surname !== guestSurname)

            axios.get(`https://nicolavitrani-a4783-default-rtdb.europe-west1.firebasedatabase.app/FNresponses/${responseId}.json`).then(response => {
                let responseToEdit = response.data;
                responseToEdit.hostsData = newHostsData;
                responseToEdit.host = newHostsData.length;

                axios.put(`https://nicolavitrani-a4783-default-rtdb.europe-west1.firebasedatabase.app/FNresponses/${responseId}.json`, responseToEdit).then(res => {
                    getAllResponses();
                }).catch(() => {
                    console.log("Edit error")
                })
            }).catch(() => {
                console.log("Get error")
            })
        }
    }

    const computeTotalGuests = () => {
        let confirmedResponses = Object.values(responses).filter(el => el.confirmed);
        let totalGuests = confirmedResponses.length;

        confirmedResponses.forEach(el => {
            totalGuests = totalGuests + Number(el.host);
        })

        return totalGuests;
    }

    const computeTotalPrice = () => {
        if ((adultPrice !== 0 && adultPrice !== '') && (babyPrice !== 0 && babyPrice !== '')) {
            let totalPrice = adultPrice * (computeTotalGuests()-babies) + (babyPrice * babies);

            setTotalPrice(totalPrice);
        }
    }

    const filterResponses = () => {
        getAllResponses(inputFilter);
    }

    if (isAuthenticated) {
        return (
            <div className="container my-5">
                <div className="row justify-content-center align-items-center">
                    <div className="col-12">
                        <h1 className="text-center">
                            Risposte degli invitati
                        </h1>
                    </div>
                </div>
                <div className="row justify-content-center align-items-center my-3">
                    <div className="col-12">
                        {responses ? (
                            <>
                                <div className="col-12 my-5">
                                    <div className="row justify-content-between fw-bold">
                                        <div className="col-12 col-md-6">
                                            <div>
                                                Risposte totali: {Object.values(responses).length}
                                            </div>
                                            <div>
                                                Inviti accettati: {Object.values(responses).filter(el => el.confirmed).length}
                                            </div>
                                            <div>
                                                Inviti rifiutati: {Object.values(responses).filter(el => !el.confirmed).length}
                                            </div>
                                            <div>
                                                Totale accettati + ospiti: {computeTotalGuests()}
                                            </div>
                                            <div>
                                                Totale manù bambini: {babies}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 mt-4 mt-md-0">
                                            <div className="row justify-content-center">
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-6 mb-3">
                                                            <label className="form-label">Prezzo adulti ({computeTotalGuests() - babies} menù)</label>
                                                            <input type="text" className="form-control" value={adultPrice} onChange={(e) => setAdultPrice(e.target.value)}></input>
                                                        </div>
                                                        <div className="col-6 mb-3">
                                                            <label className="form-label">Prezzo bambini ({babies} menù)</label>
                                                            <input type="text" className="form-control" value={babyPrice} onChange={(e) => setBabyPrice(e.target.value)}></input>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-between">
                                                        {totalPrice ? (
                                                            <div className="col-12 text-muted">
                                                                <strong className="me-2 text-black">{totalPrice}€</strong>
                                                                <span className="text-muted">(minchia che botta!!!)</span>
                                                            </div>
                                                        ) : (
                                                            <div className="col-12 text-muted">
                                                                *Inserisci entrambi i prezzi per avere il totale <br/>
                                                                (indica i decimali con il punto)
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 my-4">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-6">
                                            <input type="search" className="form-control" placeholder="Cerca per nome o cognome" value={inputFilter} onChange={(e) => setInputFilter(e.target.value)}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 my-2 table-responsive">
                                    <table className="table table-bordered table-hover">
                                        <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Nome</th>
                                            <th scope="col">Cognome</th>
                                            <th scope="col">Ospiti</th>
                                            <th scope="col" style={{minWidth: "250px"}}>Note</th>
                                            <th scope="col">Conferma</th>
                                            <th scope="col">Data</th>
                                            <th scope="col">Azioni</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {Object.values(responses).map((response, key) => {
                                            return (<>
                                                    <tr className={response.duplicate === true ? "table-danger" : ""}>
                                                        <td>{key + 1}</td>
                                                        <td>{response.name}</td>
                                                        <td>{response.surname}</td>
                                                        <td>{response.host}</td>
                                                        <td>{response.note}</td>
                                                        <td>{response.confirmed ? 'SI' : 'NO'}</td>
                                                        <td>{response.TMS.slice(0, 10)}</td>
                                                        <td style={{cursor: "pointer"}}
                                                            onClick={() => handleDeleteResponse(response.id, response.name, response.surname)}>🗑
                                                        </td>
                                                    </tr>
                                                    {response?.hostsData?.length > 0 && (
                                                    response.hostsData.map((host, hostIdx) => {
                                                        return (
                                                            <>
                                                                <tr className={host.duplicate === true ? "table-danger" : "table-success"}>
                                                                    <td>{key + 1} (ospite)</td>
                                                                    <td>{host.name}</td>
                                                                    <td>{host.surname}</td>
                                                                    <td></td>
                                                                    <td>Menù {host.menu === 'adult' ? 'adulto' : 'baby'}</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td style={{cursor: "pointer"}}
                                                                            onClick={() => handleDeleteGuest(response.id, hostIdx, host.name, host.surname)}>🗑
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })
                                                )}
                                                </>
                                            );
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        ) : (
                            <div className="col-12 text-center my-4">
                                Ancora nessuna risposta ricevuta
                            </div>
                        )}
                    </div>
                </div>
                <div className="row justify-content-center align-items-center my-3">
                    <div className="col-12 text-center">
                        <button className="btn btn-FN" onClick={handleOnClickLogout}>Logout</button>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="container my-5">
                <div className="row align-items-center justify-content-center" style={{minHeight: "80vh"}}>
                    <div className="col-12">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12">
                                <h1 className="text-center">
                                    Area riservata
                                </h1>
                            </div>
                        </div>
                        <div className="row justify-content-center my-4">
                            <div className="col 12 col-md-8 col-lg-6">
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="username" className="form-label">Username</label>
                                        <input type="text" className="form-control" id="username" required
                                               value={username} onChange={(e) => setUsername(e.target.value)}></input>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label">Password</label>
                                        <input type="password" className="form-control" id="password" required
                                               value={password} onChange={(e) => setPassword(e.target.value)}></input>
                                    </div>
                                    <button type="submit" className="btn btn-FN mx-auto my-3">Accedi</button>
                                    <a href="/" className="btn btn-FN ms-2">Torna indietro</a>
                                    {showAlertMessage && (
                                        <div className="my-3">
                                            <div className="alert alert-danger text-center">
                                                Credenziali errate, riprova
                                            </div>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminPage;