import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import axios from "axios"
import {useEffect, useState} from "react";
import AdminPage from "./AdminPage";

function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin" element={<AdminPage />} />
        </Routes>
      </Router>
  );
}

function Home() {
    const [presenceSelection, setPresenceSelection] = useState(false);
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [host, setHost] = useState(0);
    const [kidMenu, setKidMenu] = useState(false);
    const [note, setNote] = useState("");
    const [hostsName, setHostsName] = useState([]);
    const [hostsSurname, setHostsSurname] = useState([]);
    const [hostsMenu, setHostsMenu] = useState([]);

    useEffect(() => {
        document.getElementById("presenceConfirm").value = "";
    }, []);

    const handleOnChangePresence = (event) => {
        if (event.target.value === 'yes') {
            setPresenceSelection(true)
        } else {
            setPresenceSelection(false)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let data = {};
        let hostsData = [];

        hostsName.forEach((name, index) => {
            if (index < host) {
                let actualHost = {
                    name: name,
                    surname: hostsSurname[index],
                    menu: hostsMenu[index]
                }

                hostsData.push(actualHost);
            }
        });

        data.name = name;
        data.surname = surname;
        data.confirmed = presenceSelection;
        data.host = host;
        data.kidMenu = kidMenu;
        data.note = note;
        data.hostsData = hostsData;
        data.TMS = new Date().toISOString();

        axios.post(`https://nicolavitrani-a4783-default-rtdb.europe-west1.firebasedatabase.app/FNresponses.json`, data).then(response => {
            if (response.status === 200) {
                window.alert("Grazie per la risposta, a presto!")    ;
                window.location.reload();
            } else {
                window.alert("Errore nella richiesta, riprova")
            }
        });
    }

    const handleOnChangeHosts = (e) => {
        setHost(e.target.value);
    }

    const handleInputHostsName = (index, value) => {
        setHostsName((prevInputValues) => {
            const updatedInputValues = [...prevInputValues];
            updatedInputValues[index] = value;
            return updatedInputValues;
        });
    }

    const handleInputHostsSurname = (index, value) => {
        setHostsSurname((prevInputValues) => {
            const updatedInputValues = [...prevInputValues];
            updatedInputValues[index] = value;
            return updatedInputValues;
        });
    }

    const handleInputHostsMenu = (index, value) => {
        setHostsMenu((prevInputValues) => {
            const updatedInputValues = [...prevInputValues];
            updatedInputValues[index] = value;
            return updatedInputValues;
        });
    }

    const inputHosts = [];
    for (let i = 0; i < host; i++) {

        inputHosts.push(
            <div className="row justify-content-center my-4" key={i}>
                <div className="col-12 mb-3 text-center fw-bold">
                    Ospite {i+1}
                </div>
                <div className="col-12 col-md-4 mb-3">
                    <label className="form-label">Nome</label>
                    <input className="form-control" type="text" value={hostsName[i]} onChange={(e) => {
                        handleInputHostsName(i, e.target.value)
                    }} required/>
                </div>
                <div className="col-12 col-md-4 mb-3">
                    <label className="form-label">Cognome</label>
                    <input className="form-control" type="text" value={hostsSurname[i]} onChange={(e) => {
                        handleInputHostsSurname(i, e.target.value)
                    }} required/>
                </div>
                <div className="col-12 col-md-4 mb-3">
                    <label className="form-label">Tipo di menù</label>
                    <select className="form-select" value={hostsMenu[i]} onChange={(e) => {
                        handleInputHostsMenu(i, e.target.value)
                    }} required>
                        <option value="">Seleziona una risposta</option>
                        <option value="adult">Menù adulto</option>
                        <option value="baby">Menù bambino</option>
                    </select>
                </div>
            </div>
        )
    }

    return (
        <div className="container-fluid">
            <div className="row justify-content-center align-items-center min-vh-100 bg-FN1">
                <div className="col-12 text-center">
                    <h1 className="text-center font-serif">
                        <div className="text-uppercase fw-light">Fabiana</div>
                        <div className="text-center my-1 font-elegance">&</div>
                        <div className="text-uppercase fw-light">Nicola</div>
                    </h1>
                    <h2 className="mt-2 font-elegance">
                        Ci sposiamo!
                    </h2>
                </div>
            </div>
            <div className="row justify-content-center align-items-center min-vh-100 bg-FN2 py-5">
                <div className="col-12 text-center">
                    <h1 className="text-center font-serif">
                        <div className="text-uppercase fw-light text-uppercase">Le nostre nozze</div>
                    </h1>
                    <h2 className="text-center mt-3 font-serif fw-light">Ci sposeremo il 24 Giugno 2024</h2>
                </div>
                <div className="col-12 col-md-4 text-center mt-5 mt-md-0">
                    <h3 className="text-uppercase fs-5">
                        Celebrazione
                    </h3>
                    <div>
                        Ore 11:00
                    </div>
                    <div>
                        Cattedrale di Lucera
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <img src="/images/POI.png" alt="Icona del punto di interesse" className="img-fluid"
                             style={{height: "2rem"}}/> <a target={"_blank"} rel="noreferrer"
                                                           href="https://maps.app.goo.gl/QXzHMc8NZMqop8qW7"
                                                           className="text-black">Raggiungi la chiesa</a>
                    </div>
                </div>
                <div className="col-12 col-md-4 text-center mt-5 mt-md-0">
                    <h3 className="text-uppercase fs-5">
                        Ricevimento
                    </h3>
                    <div>
                        Ore 13:00
                    </div>
                    <div>
                        Villa Jamele - Peppe Zullo
                    </div>
                    <div>
                        (Orsara di Puglia FG)
                        <br/>
                        <br/>
                        <br/>
                        <img src="/images/POI.png" alt="Icona del punto di interesse" className="img-fluid"
                             style={{height: "2rem"}}/> <a target={"_blank"} rel="noreferrer"
                                                           href="https://maps.app.goo.gl/K2gMvpK9mLaiwH9y6"
                                                           className="text-black">Raggiungi la sala</a>
                    </div>
                </div>
                <div className="col-12 font-elegance text-center fs-2 mt-5 mt-md-0">
                    Speriamo che tu possa festeggiare questa giornata con noi!
                </div>
            </div>
            <div className="row justify-content-center align-items-center min-vh-100 bg-FN3 py-5">
                <div className="col-12 text-center mb-4">
                    <h1 className="text-center font-serif">
                        <div className="text-uppercase fw-light text-uppercase">Conferma la tua presenza</div>
                    </h1>
                </div>
                <form onSubmit={handleSubmit} className="col-12 col-md-10 col-lg-8">
                    <div className="row justify-content-between mb-3">
                        <div className="col-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="inputName" className="form-label">Nome</label>
                                <input type="text" className="form-control" id="inputName" aria-describedby="emailHelp"
                                       value={name} onChange={(e) => setName(e.target.value)} required></input>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="inputSurname" className="form-label">Cognome</label>
                                <input type="text" className="form-control" id="inputSurname"
                                       aria-describedby="emailHelp" value={surname}
                                       onChange={(e) => setSurname(e.target.value)} required></input>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mb-3">
                        <div className="col-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="inputName" className="form-label">Altri ospiti</label>
                                {/*<input type="number" className="form-control" id="inputNumber" min={0} max={10}*/}
                                {/*       value={host} onChange={(e) => setHost(e.target.value)} required></input>*/}
                                <input type="number" className="form-control" id="inputNumber" min={0} max={10}
                                       value={host} onChange={handleOnChangeHosts} required></input>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="mb-3">
                                <label htmlFor="presenceConfirm" className="form-label">Confermi la tua
                                    presenza?</label>
                                <select name="presenceConfirm" id="presenceConfirm" className="form-select" required  onChange={handleOnChangePresence}>
                                    <option value="">Seleziona una risposta</option>
                                    <option value="yes">Ci sarò</option>
                                    <option value="no">Mi dispiace, non potrò esserci</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {inputHosts.length > 0 && presenceSelection && (
                        <div className="row justify-content-center mb-4">
                            <div className="col-12">
                                {inputHosts}
                            </div>
                        </div>
                    )}
                    <div className="row justify-content-between mb-4">
                        <div className="col-12">
                            <div className="mb-3">
                                <label htmlFor="inputName" className="form-label">Intolleranze e/o allergie</label>
                                <textarea name="intolerances" id="intolerancesInput" cols="100%" rows="10"
                                          className="form-control" value={note}
                                          placeholder="Inserisci qui evenutali allergie, intolleranze"
                                          onChange={(e) => setNote(e.target.value)}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mb-3">
                        <div className="col-8 col-md-6 col-lg-4 text-center">
                            <button className="btn btn-FN">Conferma</button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="row align-items-center min-vh-100 bg-FN3 py-5 ps-0 ps-md-5">
                <div className="col-12 col-md-8 text-start mb-4">
                    <h1 className="font-serif">
                        <div className="text-uppercase fw-light text-uppercase">Se ti va, contribuisci al nostro viaggio
                            di nozze!
                        </div>
                    </h1>
                </div>
                <div className="col-12 col-md-6">
                    <div className="text-uppercase fs-3">
                        IBAN
                    </div>
                    <div className="fs-3 fw-light">
                        IT11C0538778440000003096481
                    </div>
                    <div className="text-uppercase fs-3 mt-3">
                        Intestatario
                    </div>
                    <div className="fs-3 fw-light">
                        Fabiana Fantini
                    </div>
                </div>
                <div className="col-12 col-md-6 text-start">
                    <img src="/images/airplane.png" alt="Icona dell'aereo" className="img-fluid"
                         style={{height: "200px"}}/>
                </div>
            </div>
            <div className="row align-items-center bg-FN3 py-5">
                <div className="col-5 text-center">
                    <a href="/admin" className="text-decoration-none">
                        <img src="/images/flowers.png" alt="Fiori di ringraziamento" className="img-fluid"/>
                    </a>
                </div>
                <div className="col-7">
                    <div className="font-elegance display-2">
                        Grazie!
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
